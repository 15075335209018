import { FirebaseOptions } from 'firebase/app'
import { z } from 'zod'

export const SENTRY_DSN =
  'https://a28a9e2e896fba09a43e8e40c3d17bd2@o1088057.ingest.us.sentry.io/4507944851603456'

// eslint-disable-next-line prefer-destructuring
export const NODE_ENV = process.env.NODE_ENV

export const API_MODE = z
  .enum(['development', 'opsonly', 'staging', 'hotfix', 'production'])
  .parse(import.meta.env.MODE)

type ValueByMode = {
  [key in typeof API_MODE]: string
}

const API_ORIGINS_POLICE_BY_MODE = {
  development: 'https://police.main.dev.zoi.com/gql',
  opsonly: 'https://police.opsonly.dev.zoi.com/gql',
  staging: 'https://police.staging.zoi.com/gql',
  hotfix: 'https://police.hotfix.dev.zoi.com/gql',
  production: 'https://police.prod.zoi.com/gql'
}
export const API_ORIGIN_POLICE = API_ORIGINS_POLICE_BY_MODE[API_MODE]

const API_ORIGINS_ZEPPELIN_BY_MODE = {
  development: 'https://zeppelin.main.dev.zoi.com/gql',
  opsonly: 'https://zeppelin.opsonly.dev.zoi.com/gql',
  staging: 'https://zeppelin.staging.zoi.com/gql',
  hotfix: 'https://zeppelin.hotfix.dev.zoi.com/gql',
  production: 'https://zeppelin.prod.zoi.com/gql'
}
export const API_ORIGIN_ZEPPELIN = API_ORIGINS_ZEPPELIN_BY_MODE[API_MODE]

export const AXEPTIO_CLIENT_ID = '647a107eeac4bba0d216447a'

export const LOCIZE_PROJECT_ID = '819218b5-353e-42f9-a130-6e91b32da821'

const MIXPANEL_TOKEN_BY_MODE = {
  development: 'bd39aa2355cabea81645ec87ff2bfc09',
  opsonly: 'bd39aa2355cabea81645ec87ff2bfc09',
  staging: 'bd39aa2355cabea81645ec87ff2bfc09',
  hotfix: 'bd39aa2355cabea81645ec87ff2bfc09',
  production: 'a30de69b61192d352c4dac6d71922713'
} satisfies ValueByMode

export const MIXPANEL_TOKEN = MIXPANEL_TOKEN_BY_MODE[API_MODE]

export const GOOGLE_TAG_MANAGER_ID = 'GTM-NS68BL56'

export const META_PIXEL_ID = '1009947164038151'

export const ORIGINS_WEBSITE_BY_MODE = {
  development: 'https://website.main.dev.zoi.com',
  opsonly: 'https://website.opsonly.dev.zoi.com',
  staging: 'https://website.staging.zoi.com',
  hotfix: 'https://website.hotfix.dev.zoi.com',
  production: 'https://www.zoi.com'
} satisfies ValueByMode

export const ORIGIN_WEBSITE = ORIGINS_WEBSITE_BY_MODE[API_MODE]

const HUBSPOT_PORTAL_ID_BY_MODE = {
  development: '143397965',
  opsonly: '143397965',
  staging: '143397965',
  hotfix: '143397965',
  production: '143187250'
} satisfies ValueByMode

export const HUBSPOT_PORTAL_ID = HUBSPOT_PORTAL_ID_BY_MODE[API_MODE]

const HUBSPOT_COMPANY_FORM_ID_BY_MODE = {
  development: '4e618b3d-56e4-4052-97ce-b130a2a24822',
  opsonly: '4e618b3d-56e4-4052-97ce-b130a2a24822',
  staging: '4e618b3d-56e4-4052-97ce-b130a2a24822',
  hotfix: '4e618b3d-56e4-4052-97ce-b130a2a24822',
  production: 'a2f6b0a2-de31-42c5-904d-a08fc0696f1b'
} satisfies ValueByMode

export const HUBSPOT_COMPANY_FORM_ID = HUBSPOT_COMPANY_FORM_ID_BY_MODE[API_MODE]

const HUBSPOT_CONTACT_US_FORM_ID_BY_MODE = {
  development: '10b2f43d-06db-415f-84a5-1c5b56ce92fd',
  opsonly: '10b2f43d-06db-415f-84a5-1c5b56ce92fd',
  staging: '10b2f43d-06db-415f-84a5-1c5b56ce92fd',
  hotfix: '10b2f43d-06db-415f-84a5-1c5b56ce92fd',
  production: '21fcc4ba-ec71-406d-87fd-a9a8b43f4fe2'
} satisfies ValueByMode

export const HUBSPOT_CONTACT_US_FORM_ID =
  HUBSPOT_CONTACT_US_FORM_ID_BY_MODE[API_MODE]

const FIREBASE_CONFIG_BY_MODE: Record<typeof API_MODE, FirebaseOptions> = {
  development: {
    apiKey: 'AIzaSyA36iupeRDFYGZzp5MiyIYZJKuE21PnZVc',
    authDomain: 'zoi-dev-362414.firebaseapp.com',
    projectId: 'zoi-dev-362414',
    storageBucket: 'zoi-dev-362414.appspot.com',
    messagingSenderId: '1087451810704',
    appId: '1:1087451810704:web:2e2e48d573f47cd002c115',
    measurementId: 'G-Z03D49EY0C'
  },
  opsonly: {
    apiKey: 'AIzaSyA36iupeRDFYGZzp5MiyIYZJKuE21PnZVc',
    authDomain: 'zoi-dev-362414.firebaseapp.com',
    projectId: 'zoi-dev-362414',
    storageBucket: 'zoi-dev-362414.appspot.com',
    messagingSenderId: '1087451810704',
    appId: '1:1087451810704:web:2e2e48d573f47cd002c115',
    measurementId: 'G-Z03D49EY0C'
  },
  hotfix: {
    apiKey: 'AIzaSyA36iupeRDFYGZzp5MiyIYZJKuE21PnZVc',
    authDomain: 'zoi-dev-362414.firebaseapp.com',
    projectId: 'zoi-dev-362414',
    storageBucket: 'zoi-dev-362414.appspot.com',
    messagingSenderId: '1087451810704',
    appId: '1:1087451810704:web:2e2e48d573f47cd002c115',
    measurementId: 'G-Z03D49EY0C'
  },
  staging: {
    apiKey: 'AIzaSyBVtsuqDyBfRGulebKpDkGDVcsT8C60zAQ',
    authDomain: 'zoi-staging-4624d.firebaseapp.com',
    projectId: 'zoi-staging-4624d',
    storageBucket: 'zoi-staging-4624d.appspot.com',
    messagingSenderId: '66021002989',
    appId: '1:66021002989:web:fb0b3d4015cc598d5306e4',
    measurementId: 'G-2LEFBLBD46'
  },
  production: {
    apiKey: 'AIzaSyBbE8RYZHmD-VJwR_x7EOPbhnF5HtTrR68',
    authDomain: 'zoi-production-3d52e.firebaseapp.com',
    projectId: 'zoi-production-3d52e',
    storageBucket: 'zoi-production-3d52e.appspot.com',
    messagingSenderId: '706198108010',
    appId: '1:706198108010:web:1bcebd870f1139d2309713',
    measurementId: 'G-7EB86VD9PZ'
  }
}

export const FIREBASE_CONFIG = FIREBASE_CONFIG_BY_MODE[API_MODE]

const MOBILE_APP_URLS_BY_MODE = {
  development: 'https://zoi-dev.app.link/9srVnQsywNb',
  opsonly: 'https://zoi-dev.app.link/9srVnQsywNb',
  staging: 'https://zoi-staging.app.link/y32sxWAywNb',
  hotfix: 'https://zoi-staging.app.link/y32sxWAywNb',
  production: 'https://zoi.app.link/yt5y6sYowNb'
} satisfies ValueByMode

export const MOBILE_APP_URL = MOBILE_APP_URLS_BY_MODE[API_MODE]
